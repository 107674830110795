import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router, Event } from "@angular/router";

// Variables
import { InputSupport } from "../model/inputsupport.model";
import { InputSupportDetail } from "../model/inputsupportdetail.model";
import { CatalogValue } from "../model/inputsupportdetail.model";
import { environment } from "../../../environments/environment";
import { BenefitProgramTemplate } from "../../components/benefitprogramtemplate/benefitprogramtemplate.model";
import { CatalogValueResult } from "../model/catalogvalue.model";
import {
  BenefitProgramAssign,
  BenefitProgram,
} from "../model/benefitprogramassign.model";
import { Countries, Country } from "../model/country.model";
import { UserProfileModel } from "../model/profile.model";
import {
  Corporate,
  CorporateCompleteModel,
  CorporateBusinessesModel,
  CorporateLegalEntitiesModel,
  CorporateCustomRegionsModel,
  CorporateCountryPresentModel,
  CorporateCompanyModel,
  CorporateCompanyCityModel,
} from "../model/corporate.model";
import {
  ContactModel,
  Contact,
  ContactCompanyModel,
  ContactBenefitProgramModel,
} from "../model/contact.model";
import { CompanySubModel } from "../model/company.model";
import { Article } from "../model/Articles.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AltaBmsService {
  //Review this with the JWT in App Module (why not send the token in the request?)

  bearerToken: String = "";
  headers: HttpHeaders;

  constructor(private http: HttpClient, private router: Router) {
    const me = this;

    me.bearerToken =
      localStorage.getItem("access_token") === null
        ? ""
        : localStorage.getItem("access_token").replace(/["']/g, "");
    me.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${me.bearerToken}`,
    });

    this.router.events.subscribe((event: Event) => {
      me.bearerToken =
        localStorage.getItem("access_token") === null
          ? ""
          : localStorage.getItem("access_token").replace(/["']/g, "");
      me.headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${me.bearerToken}`,
      });
    });
  }


  login(username: string, password: string): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(username + ":" + password),
      'Skip': 'true'
    });

    return this.http.get<any>(`${environment.apiUrl}/api/authentication/login`, { headers: headers })
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  //Input Support List
  getCatalogValueList(
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/input-support/detail?start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as InputSupportDetail;
        })
      );
  }

  getBenefitPlanListByCompany (
    companyId: 0
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/benefit-plan/${companyId}/list`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as InputSupportDetail;
        })
      );
  }

  getCatalogList() {
    return this.http
      .get(`${environment.apiUrl}/api/input-support`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result as InputSupport;
        })
      );
  }

  createCatalogValue(catalogoId: number, description: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/input-support/${catalogoId}/detail`,
        JSON.stringify(description), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCatalogValue(catalogId: number, id: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/input-support/${catalogId}/detail/${id}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCatalogValue(catalogValueId: number, catalogValue: CatalogValue) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/input-support/detail/${catalogValueId}/update`,
        JSON.stringify(catalogValue), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Benefit Program
  getTemplateList() {
    return this.http
      .get(`${environment.apiUrl}/api/benefit-program-template`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result as BenefitProgramTemplate;
        })
      );
  }

  getCatalogValueResultList() {
    return this.http
      .get(`${environment.apiUrl}/api/catalog-value`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result as CatalogValueResult;
        })
      );
  }

  getCatalogValueByCatalogId(catalogId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/catalog-value/${catalogId}`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result as CatalogValueResult;
        })
      );
  }

  getListFromStatusTracking(catalogId: number, entityId: number) {
    return this.http
      .get(
        `${environment.apiUrl}/api/catalog-value/${catalogId}/status-tracking/${entityId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as CatalogValueResult;
        })
      );
  }

  createBenefitProgram(templateId: number, catalogValueid: number) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/benefit-program/${templateId}/catalog-value/${catalogValueid}`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getBenefitProgramList(
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/benefit-program?start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as BenefitProgramAssign;
        })
      );
  }

  deleteBenefitProgram(id: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/benefit-program/${id}/detail`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateBenefitProgram(benefitProgramAssignId: number, record: BenefitProgram) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/benefit-program/${benefitProgramAssignId}/update`,
        JSON.stringify(record), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Translate Entity
  getTranslateEntity(screenEntity: string) {
    return this.http
      .get(`${environment.apiUrl}/api/settings/${screenEntity}/translate`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Country
  getCountryList(
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/country?start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Countries;
        })
      );
  }

  getCountryBySearch(search?: string) {
    return this.http
      .get(`${environment.apiUrl}/api/country/searching?search=${search}`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result as Countries;
        })
      );
  }

  getCountryByCorporateList(corporateId: number, search?: string) {
    return this.http
      .get(
        `${environment.apiUrl}/api/country-corporate/${corporateId}/searching?search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Countries;
        })
      );
  }

  GetCountryById(countryId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/country-corporate/${countryId}`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result as Country;
        })
      );
  }

  getCountriesFromAPIRest() {
    return this.http.get(`https://restcountries.com/v2/all`, {}).pipe(
      map((result) => {
        return result;
      })
    );
  }

  updateCountriesFromApi(record: Country[]) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/country/update-from-api`,
        record, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Profile
  getLanguageList() {
    return this.http
      .get(`${environment.apiUrl}/api/settings/languages`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCountries() {
    return this.http
      .get(`${environment.apiUrl}/api/countries`, { headers: this.headers })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateProfile(record: UserProfileModel) {
    const user = JSON.parse(localStorage.currentUser);
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/settings/profile/${user.Profile.UserId}/update`,
        JSON.stringify(record), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getProfile() {
    const user = JSON.parse(localStorage.currentUser);
    return this.http
      .get<any>(
        `${environment.apiUrl}/api/settings/profile/${user.Profile.UserId}`,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadFile(formData: FormData) {
    let token = this.bearerToken;
    return this.http
      .post<any>(`${environment.apiUrl}/api/settings/upload`, formData, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
        }),
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  logout(lastScreen: string) {
    const user = JSON.parse(localStorage.currentUser);
    localStorage.clear();
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/authentication/${user.Profile.UserId}/logout`,
        JSON.stringify(lastScreen), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  changePassword(newPassword: string) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/authentication/password/change`,
        JSON.stringify(newPassword),
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate
  getCorporateList(
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate?start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  createCorporate(corporate: CorporateCompleteModel) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate`,
        corporate, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporate(corporateId: number, corporate: CorporateCompleteModel) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/update`,
        corporate, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCorporate(corporateId: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadCorporateLogo(corporateId: number, formData: FormData) {
    let token = this.bearerToken;
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/upload`,
        formData,
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
          }),
        }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCorporate(corporateId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/corporate/${corporateId}/edit`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result as CorporateCompleteModel;
        })
      );
  }

  // Corporate Businesses Services
  getCorporateBusinessesByCorporate(
    corporateId: number,
    companyLegalEntityId: number
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/businesses/${companyLegalEntityId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as CorporateBusinessesModel;
        })
      );
  }

  getCorporateEntitiesByCorporate(
    corporateId: number,
    companyLegalEntityId: number
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/corporateentities/${companyLegalEntityId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as CorporateLegalEntitiesModel;
        })
      );
  }

  getCorporateBusinessesList(
    corporateId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/businesses?corporateId=${corporateId}&start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  createCorporateBusinesses(corporateId: number, description: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/businesses`,
        JSON.stringify(description), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporateBusinesses(
    corporateBusinessesId: number,
    corporateBusinessesModel: CorporateBusinessesModel
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/businesses/${corporateBusinessesId}/update`,
        JSON.stringify(corporateBusinessesModel), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCorporateBusinesses(
    corporateId: number,
    corporateBusinessesId: number
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/businesses/${corporateBusinessesId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate Legal Entities Services

  getCorporateLegalEntitiesList(
    corporateId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/legal-entities?corporateId=${corporateId}&start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  createCorporateLegalEntities(corporateId: number, description: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/legal-entities`,
        JSON.stringify(description), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporateLegalEntities(
    corporateLegalEntitiesId: number,
    corporateLegalEntitiesModel: CorporateLegalEntitiesModel
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/legal-entities/${corporateLegalEntitiesId}/update`,
        JSON.stringify(corporateLegalEntitiesModel), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCorporateLegalEntities(
    corporateId: number,
    corporateLegalEntitiesId: number
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/legal-entities/${corporateLegalEntitiesId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCompanies(
    corporateId: number,
    countryId?: number,
    companyId?: number,
    region?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/companies?countryId=${countryId}&companyId=${companyId}&region=${region}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as CompanySubModel;
        })
      );
  }

  getCorporateAnnualInformation(corporateId: number, year: number) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/annual-information/${year}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as CatalogValueResult;
        })
      );
  }

  getCorporateBusinessList(corporateId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/corporate/${corporateId}/businesses`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCorporateEntityList(corporateId: number) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/legal-entities`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCompanyLegalEntity(companyId: number, id: number) {
    return this.http
      .get(`${environment.apiUrl}/company/${companyId}/legal-entity/${id}`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCompanyLegalEntityByCompany(companyId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/company/${companyId}/legal-entity-all`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate Custom Regions
  getCorporateCustomRegionsList(
    corporateId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/custom-regions?corporateId=${corporateId}&start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  getCorporateCustomRegionsByCorporateList(corporateId: number) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/custom-regions`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  createCorporateCustomRegions(
    corporateId: number,
    customRegionsToSave: CorporateCustomRegionsModel
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/custom-regions`,
        customRegionsToSave,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporateCustomRegions(
    corporateRegionId: number,
    customRegionsToSave: CorporateCustomRegionsModel
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/custom-regions/${corporateRegionId}/update`,
        JSON.stringify(customRegionsToSave), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCorporateCustomRegions(corporateId: number, corporateRegionId: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/custom-regions/${corporateRegionId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate Countries Presents
  getCorporateCountriesPresentsList(
    corporateId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/countries-presents?corporateId=${corporateId}&start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  createCorporateCountriesPresents(
    corporateId: number,
    recordToSave: CorporateCountryPresentModel
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/countries-presents`,
        recordToSave,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporateCountriesPresents(
    corporateCountryPresentId: number,
    recordToSave: CorporateCountryPresentModel
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/countries-presents/${corporateCountryPresentId}/update`,
        JSON.stringify(recordToSave), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCountriesPresents(
    corporateId: number,
    corporateCountryPresentId: number
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/countries-presents/${corporateCountryPresentId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate Company
  getCorporateCompanyList(
    corporateId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/company?corporateId=${corporateId}&start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  createCorporateCompany(
    corporateId: number,
    recordToSave: CorporateCompanyModel
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/company`,
        recordToSave,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporateCompany(
    corporateCompanyId: number,
    recordToSave: CorporateCompanyModel
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/company/${corporateCompanyId}/update`,
        JSON.stringify(recordToSave), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCompany(corporateId: number, corporateCompanyId: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/company/${corporateCompanyId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate Company City
  getCorporateCompanyCityList(
    corporateCompanyId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/company-city?corporateCompanyId=${corporateCompanyId}&start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Corporate;
        })
      );
  }

  createCorporateCompanyCity(
    corporateCompanyId: number,
    recordToSave: CorporateCompanyCityModel
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateCompanyId}/company-city`,
        recordToSave,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporateCompanyCity(
    corporateCompanyCityId: number,
    recordToSave: CorporateCompanyCityModel
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/company-city/${corporateCompanyCityId}/update`,
        JSON.stringify(recordToSave), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCompanyCity(
    corporateCompanyId: number,
    corporateCompanyCityId: number
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateCompanyId}/company-city/${corporateCompanyCityId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // filter for company detail
  getCorporateCompanyRegions(corporateId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/corporate/${corporateId}/regions`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCorporateCompanyCountries(corporateId: number, region?: string) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/region/countries?region=${region}`,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate Contact
  getContactyByCorporateList(
    corporateId: number,
    companyIds: string,
    hasFilter: boolean = false,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string,
    sortField?: string,
    viewContactsWithCompanies?: boolean
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/contact?corporateId=${corporateId}&companyIds=${companyIds}&hasFilter=${hasFilter}&start=${start}&limit=${limit}&dir=${dir}&search=${search}&sortField=${sortField}&viewContactsWithCompanies=${viewContactsWithCompanies}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as Contact;
        })
      );
  }

  getContactsCompany(corporateId: number, benefitPlanId: number, benefitTypeId: number, vendorId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/corporate/${corporateId}/contacts-company?benefitPlanId=${benefitPlanId}&benefitTypeId=${benefitTypeId}&vendorId=${vendorId}`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getContactsCorporate(corporateId: number, contactCompanyId: number = 0, countryId: number = 0, regionName: string = null) {
    return this.http
      .get(`${environment.apiUrl}/api/corporate/${corporateId}/contacts-corporate?contactCompanyId=${contactCompanyId}&countryId=${countryId}${!!regionName ? '&regionName=' + regionName : '' }`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  createCorporateContact(corporateId: number, recordToSave: ContactModel) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/contact`,
        recordToSave,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCorporateContact(contactId: number, recordToSave: ContactModel) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/contact/${contactId}/update`,
        JSON.stringify(recordToSave), // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteCorporateContact(corporateId: number, contactId: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/${corporateId}/contact/${contactId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate
  getCorporateCompanyByCorporate(corporateId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/corporate/${corporateId}/company`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Corporate Contact Company
  getContactCompanyList(
    contactId: number,
    corporateId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/contact-company/${contactId}?start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as ContactCompanyModel;
        })
      );
  }

  getCorporateCompanyContact(contactId: number, corporateId: number) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/company/contact-company/${contactId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as CorporateCompanyModel;
        })
      );
  }

  createContactCompany(recordToSave: ContactCompanyModel[]) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/contact-company`,
        recordToSave,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteContactCompany(contactCompanyId: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/contact-company/${contactCompanyId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Contact Benefit Program
  getContactBenefitProgramList(
    contactId: number,
    corporateId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string
  ) {
    return this.http
      .get(
        `${environment.apiUrl}/api/corporate/${corporateId}/contact-benefit-program/${contactId}?start=${start}&limit=${limit}&dir=${dir}&search=${search}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as ContactBenefitProgramModel;
        })
      );
  }

  getBenefitProgramCorporateContact(contactId: number, corporateId: number) {
    return this.http
      .get(
        `${environment.apiUrl}/api/catalog-value/corporate/${corporateId}/contact-benefit-program/${contactId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((result) => {
          return result as CorporateCompanyModel;
        })
      );
  }

  createContactBenefitProgram(recordToSave: ContactBenefitProgramModel[]) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/corporate/contact-benefit-program`,
        recordToSave,
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteContactBenefitProgram(contactBenefitProgramId: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/corporate/contact-benefit-program/${contactBenefitProgramId}/delete`,
        {}, // body
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //Generic
  getPagingList(url: string, start: number = 1, limit: number = 10, dir: string = "DESC", search?: string, category?: string, sortField?: string, extra: Map<string, any> = null) {

    let endPoint: string = `${environment.apiUrl}/api/${url}?start=${start}&limit=${limit}&dir=${dir}&search=${search}&cat=${category}&sortField=${sortField}`;

    if(extra != null) {
      for(let key of extra.keys())
        endPoint += `&${key}=${extra.get(key)}`
    }

    return this.http.get(endPoint, { headers: this.headers }).pipe(map((result) => result));
  }

  getActiveReports() {
    return this.http.get(`${environment.apiUrl}/api/report/research/active-reports`).pipe(map((result) => result))
  }

  getList(url) {
    return this.http
      .get<any>(`${environment.apiUrl}/api/${url}`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  get(url: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/api/${url}`, { headers: this.headers })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  postData(url: string, record: object) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/${url}`,
        JSON.stringify(record), // body{
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  putData(url: string, record: object) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/${url}`,
        JSON.stringify(record), // body{
        { headers: this.headers }
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteData(url: string) {
    return this.http
      .delete<any>(`${environment.apiUrl}/api/${url}`, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadFileGlobal(url: string, formData: FormData) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/${url}`, formData, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.bearerToken}`,
        }),
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteDataPut(url: string) {
    return this.http
      .put<any>(`${environment.apiUrl}/api/${url}`, null, {
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getAvailableContactList(  
    contractId: number,
    start: number = 1,
    limit: number = 10,
    dir: string = "DESC",
    search?: string,
    vendorId?: number
  ) {
    return this.get(
      `benefit-program/contract/${contractId}/available-contacts?start=${start}&limit=${limit}&dir=${dir}&search=${search}&vendorId=${vendorId}`
    ).pipe(
      map((result) => {
        return result;
      })
    );
  }

  getHighChartsInformation(alpha2Code: string) {
    return this.http
      .get(
        `https://code.highcharts.com/mapdata/countries/${alpha2Code}/${alpha2Code}-all.geo.json`,
        // { headers: this.headers }
        {}
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //reports
  exportReportFromServer(url: string, report: {}){
    return this.http
    .post<any>(
      `${environment.apiUrl}/api/${url}`,
      JSON.stringify(report), //body{
      {headers: this.headers, responseType: 'arraybuffer' as 'json'}
    )
    .pipe(
      map(result => {
        return result;
      })
    );
  }

  deleteReportResearch(reportResearchId: number) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/report/research/${reportResearchId}/delete`,
        {},
        { headers: this.headers })
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCurrencies() {
    return this.http.get<any>(`${environment.apiUrl}/api/currency/`, { headers: this.headers }).pipe(map((result) => result));
  }

  getCurrencyByCountry(countryid: number) {
    return this.http.get<any>(`${environment.apiUrl}/api/currency/country/${countryid}`, { headers: this.headers }).pipe(map((result) => result));
  }

  getCurrency(currencyId: number){
    return this.http.get<any>(`${environment.apiUrl}/api/currency/${currencyId}`, { headers: this.headers }).pipe(map((result) => result));
  }


  deleteBenefitProgramValue(planId: number, id: number, record:any) {
    return this.http.post(
      `${environment.apiUrl}/api/benefit-program/plan/${planId}/id/${id}/delete-values`, 
      JSON.stringify(record), // body{
      { headers: this.headers }).pipe(map((result) => result));
  }

}
