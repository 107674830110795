import { Injectable } from "@angular/core";
import { isNullOrUndefined } from "util";
import { ToastrService } from "ngx-toastr";
import { LocalstorageService } from "./localstorage.service";
import * as ActionEnum from "../../shared/appactions.json";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  permissions: any = {};
  actions: any;
  constructor(
    private alert: ToastrService,
    private localStorageService: LocalstorageService
  ) {
    this.actionsByUser().then((result) => {
      // console.log('actionsByUser result', result);

      this.permissions = result;
    });
    this.actions = ActionEnum["default"];
  }

  reloadPermissions() {
    return new Promise((resolve) => {
      this.actionsByUser().then((result) => {
        console.log('reloadPermissions actionsByUser result', result);

        this.permissions = result;
        this.actions = ActionEnum["default"];
        resolve(true);
      });
    });
  }

  getDateFormat(date: string) {
    if (!isNullOrUndefined(date)) {
      var currentDate = new Date(date);
      var day = ("0" + currentDate.getDate()).slice(-2);
      var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      var dateFormatted = currentDate.getFullYear() + "-" + month + "-" + day;
      return dateFormatted;
    } else return null;
  }

  //Table row group span
  updateRowGroupMetaData(
    rowGroupMetadata: any,
    columDetailLazy: any[],
    columnGroup: string,
    rowIndex: number = 0
  ) {
    rowGroupMetadata = {};

    if (columDetailLazy) {
      for (let i = 0; i < columDetailLazy.length; i++) {
        let rowData = columDetailLazy[i];
        let columnRowGroup = rowData[columnGroup];
        if (i == 0) {
          rowGroupMetadata[columnRowGroup] = { index: 0 + rowIndex, size: 1 };
        } else {
          let previousRowData = columDetailLazy[i - 1];
          let previousRowGroup = previousRowData[columnGroup];
          if (columnRowGroup === previousRowGroup)
            rowGroupMetadata[columnRowGroup].size++;
          else
            rowGroupMetadata[columnRowGroup] = { index: i + rowIndex, size: 1 };
        }
      }
      return rowGroupMetadata;
    }
  }

  getErrorMessages(component: any, error: any) {
    let me = component;

    if (error.error.Messages != null && error.error.Messages.length > 0) {
      let map = error.error.Messages;
      Object.keys(map).forEach(function (key) {
        if (me.toastr === undefined)
          this.alert.error(map[key].Message, "Error");
        else me.toastr.error(map[key].Message, "Error");
      });
    } else {
      if (me.toastr === undefined)
        this.alert.error(error.error.Message, "Error");
      else me.toastr.error(error.error.Message, "Error");
    }
    component.saving = false;

    return error?.error?.Message ?? "";
  }

  getErrorMessageText(error: any) {
    return error?.error?.Message ?? "";
  }

  getDate(currentDate) {
    if (currentDate === null) return null;

    if (currentDate.length > 10) currentDate = currentDate.substring(0, 10);
    
    currentDate = moment(currentDate).toDate();

    return currentDate;
  }

  getDateString(currentDate) {
    if (currentDate === null || currentDate === "") return "";

    return moment(currentDate).format("MM-DD-YYYY");
  }

  getDateToSave(currentDate) {
    if (currentDate === null || currentDate === "") return "";

    var utcDate = new Date(
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
    );
    return utcDate.toJSON().replace("Z", "");
  }

  convertFirstWordToUpperCase(word: string) {
    return word.substring(0, 1).toUpperCase() + word.substring(1);
  }

  numberWithCommas(x) {
    if(x == undefined || x == null)
      return x;
      
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  numberWithDecimals(value, decimals) {
    if(value == undefined || value == null)
      return value;

    return parseInt(parseInt(value).toFixed(decimals));
  }

  numberWithDecimalsAndCommas(value, decimals) {
    if(value == undefined || value == null)
      return value;

    return this.numberWithCommas(this.numberWithDecimals(value, decimals));
  }
  
  addZerosToInteger(value, zeros: string) {   
    const resultValue = Number.isInteger(value) ? value + '.' + zeros : value;
    return resultValue;
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  getBase64(imgUrl) {
    const self = this;
    var xhr = new XMLHttpRequest();
    // xhr.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    // xhr.setRequestHeader('Authorization', 'Basic [base64 encoded password here]' );

    xhr.open("GET", imgUrl, true);
    // Essential
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status == 200) {
        //Get a blob objects
        var blob = this.response;        
        //  Essential
        let oFileReader = new FileReader();
        oFileReader.onloadend = function (e) {
          let base64 = e.target;
          return (<any>base64).result;          
        };
        oFileReader.readAsDataURL(blob);
        //==== In order to display the picture on the page, you can delete ====
        // var img = document.createElement("img");
        // img.onload = function (e) {
        //   window.URL.revokeObjectURL(img.src); //  Clear release
        // };
        // let src = window.URL.createObjectURL(blob);
        // img.src = src
        // document.getElementById("container1").appendChild(img);
        //==== In order to display the picture on the page, you can delete ====
      }
    };
    xhr.send();
  }

  validateAccessToAction(action) {
    if (this.permissions[action] === undefined) {
      this.alert.error("You don't have permission to this action", "Error");
      return false;
    } else return true;
  }

  havePermission(action) {
    return this.permissions[action] !== undefined;
  }

  actionsByUser() {
    return new Promise((resolve) => {
      let result = true;
      const userString = this.localStorageService.getUserFromStorage();
      let currentUser;
      let useractions = {};

      // console.log('actionsByUser userString', userString)

      if (!isNullOrUndefined(userString)) {

        currentUser = JSON.parse(userString);
        const actions = JSON.parse(currentUser["Profile"].Actions);

        if (actions && actions != null) {
          actions.map((c) => {
            useractions[c.AId] = 1;
          });
        }
      }

      resolve(useractions);
    });
  }

  setUserData(user) {
    localStorage.setItem(
      "currentUser",
      JSON.stringify({ Profile: user["Profile"] })
    );
    localStorage.setItem("access_token", JSON.stringify(user["Token"]));
  }

  clearCache() {    
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("access_token");
  }

  adjustTextArea(textareas) {
    let lap = 0;
    let interval = setInterval(() => {
      let pass = false;
      lap += 1;
      textareas.forEach(textarea => {
        if(textarea.nativeElement.value != '' && textarea.nativeElement.scrollHeight > 0) {
          pass = true;
        }
      })
      if(pass) {
        textareas.forEach(textarea => {
          if( textarea.nativeElement.scrollHeight > 0) {
            textarea.nativeElement.style.height = textarea.nativeElement.scrollHeight + 2 + 'px';
          }
        });
        clearInterval(interval);
      }
      if(lap == 200 && !pass) {
        clearInterval(interval);
      }
    },50);  
  }

  liabilityOrder(liability) {
    let goingFirst = 0;
    
    if(liability == 'US GAAP') {
      //US GAAP 403
      goingFirst = 403
    } else if(liability == 'IFRS') {
      //IFRS 404 
      goingFirst = 404
    } else {
      // HQ GAAP 402
      goingFirst = 402
    }
    let order = [403,404,402,405,406,407,409];
    if(goingFirst > 0) {
      order = order.filter(c => c != goingFirst);
      order.unshift(goingFirst);
    }
    return order 
  }

  public get loggedIn(): boolean {
    return localStorage.getItem("access_token") !== null;
  }
}
