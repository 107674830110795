import { ResourceSectionService } from './shared/service/resources/resource-section.service';
import { Injectable } from "@angular/core";
import { INavData } from "@coreui/angular";
import { UtilityService } from "../app/shared/service/utility.service";
import { ScreenActions } from './shared/ScreenActions';
import { forkJoin, Observable, of, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class navBarItems {

  readonly actions = ScreenActions;
  
  private navItems$: Subject<Array<INavData>> = new Subject<Array<INavData>>();

  constructor(private utilityService: UtilityService, private resourceSectionService: ResourceSectionService) { }

  public get navItems() {
    return this.navItems$.asObservable();
  }

  getClass = (action: any) => this.utilityService.havePermission(action) ? "" : "hidden";

  getMenu(translate: any): Observable<Array<INavData>> {
    
    const navItems$ = this.getNavItems(translate);
    const helpSections$ = this.resourceSectionService.getList();

    forkJoin({
      navItems: navItems$, 
      helpSections: helpSections$
    })
    .subscribe({
      next: (data) => {
        
        const helpNavItems = data.helpSections.map(section => {
          return {
            class: 'nav-link__item ',
            name: section.Description,
            url: '*',            
            children: (section.SubSections ?? []).map(section => {
              return {
                class: 'nav-link__item__children ',
                name: section.Description,
                url: `help/${section.Description}`,
              }
            })
          }
        })

        data.navItems.push(
          {
            class: this.getClass(this.actions.ViewHelp),
            url: '*',
            name: 'Help',
            icon: 'fa fa-question-circle la-lg',
            children: [...helpNavItems,
              {
                class: this.getClass(this.actions.ViewContactAlta),
                url: 'help/contact',
                name: 'Contact HUB Global Benefits Online',                
              }
            ]
          }
        );

        this.navItems$.next(data.navItems);
      }
    })

    return this.navItems$;
  }

  getNavItems(translate: any): Observable<Array<INavData>> {

    return of([
      {
        class: this.getClass(this.actions.ViewHome),
        name: translate?.MenuHome || "Home",
        url: "/home",
        icon: "las la-home la-lg",
      },
      {
        class: this.getClass(this.actions.ViewMyGeographiesMenu),
        name: translate?.MenuGeography || "My Geographies",
        url: "/geography",
        icon: "las la-globe-americas la-lg",
      },
      {
        class: this.getClass(this.actions.ViewMyBenefitsMenu),
        name: translate?.MenuBenefitPlans || "My Benefits",
        url: "*",
        icon: "las la-handshake la-lg",
        children: [
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewRetirementSubMenu),
            name: translate?.MenuBenefitPlans_Retirement || "Retirement",
            url: ["/mybenefit", "retirement"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewHealthcareSubMenu),
            name: translate?.MenuBenefitPlans_Healthcare || "Healthcare",
            url: ["/mybenefit", "healthcare"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewDeathSubMenu),
            name: translate?.MenuBenefitPlans_Death || "Death",
            url: ["/mybenefit", "death"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewDisabilitySubMenu),
            name: translate?.MenuBenefitPlans_Disability || "Disability",
            url: ["/mybenefit", "disability"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewAccidentSubMenu),
            name: translate?.MenuBenefitPlans_Accident || "Accident",
            url: ["/mybenefit", "accident"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewCompanyCarsSubMenu),
            name: translate?.MenuBenefitPlans_CompanyCar || "Company Car",
            url: ["/mybenefit", "companycar"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewLeavesSubMenu),
            name: translate?.MenuBenefitPlans_Leaves || "Leaves",
            url: ["/mybenefit", "leaves"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewOtherBenefitsSubMenu),
            name: translate?.MenuBenefitPlans_OtherBenefits || 'Other Benefits',
            url: ['/mybenefit', 'otherbenefits'],
            
          },
        ],
      },
      {
        class: this.getClass(this.actions.ViewReportsMenu),
        name: translate?.MenuReports || "HUB Reports",
        url: "/report",
        icon: "las la-clipboard-list la-lg",
        children: [
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewBenefitInventoryAllReports),
            name: translate?.MenuReports_BenefitInventory || 'Benefit Inventory',
            url: ['/report', 'bi'],              
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewAnalyticsAllReports),
            name: translate?.MenuReports_Analitycs || "Analitycs Reports",
            url: "/report/ao",              
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewProjectManagementAllReports),
            name: translate?.MenuReports_ProjectManagement || 'Project Management',
            url: ['/report', 'pm'],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewResearchAllReports),
            name: translate?.MenuReports_Research || "Research",
            url: ["/report", "rs"],
                          
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewCommunicationAllReports),
            name: translate?.MenuReports_Communication || "Communication",
            url: ["/report", "ct"],              
            
          },
        ],
      },
      {
        class: this.getClass(this.actions.ViewApexAnalyticsMenu),
        name: translate?.MenuBMSAnalytics || 'HUB Analytics',
        url: '/analytics',
        icon: 'las la-chart-bar la-lg',
        children: [
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewBuildIn),
            name: translate?.MenuBMSAnalytics_BuiltIn || "Dashboards",
            url: '/analytics/dashboard',
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewAdHoc),
            name: translate?.MenuBMSAnalytics_AdHoc || "Ad Hoc",
            url: "*",
            
          },
        ],
      },
      {
        class: this.getClass(this.actions.ViewMyCalendar),
        name: translate?.MenuCalendar || 'My Calendar',
        url: '*',
        icon: 'las la-calendar la-lg',
      },
      {
        class: this.getClass(this.actions.ViewMyDocuments),
        name: translate?.MenuMyDocuments || 'My Documents',
        url: '/mydocuments',
        icon: 'las la-copy la-lg',
      },
      {
        class: this.getClass(this.actions.ViewMyProjectsMenu),
        name: translate?.MenuProjects || "My Projects",
        url: "/myprojects",
        icon: "las la-sitemap la-lg",
        children: [
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewInsuranceRenewals),
            name: translate?.MenuProjects_Insurance || "Insurance Renewals",
            url: ['/myprojects', 'insurance-renewals'],     
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewPensionGovernance),
            name: translate?.MenuProjects_Pension || "Pension Governance",
            url: "*",
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewMAProjectsGovernance),
            name: translate?.MenuProjects_MAProject || "M&A Projects",
            url: "*",
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewBenefitInitiatives),
            name: translate?.MenuProjects_Benefit || "Benefit Initiatives",
            url: "*",
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewHQProjects),
            name: translate?.MenuProjects_HQ || "HQ Projects",
            url: "*",
            
          },
        ],
      },
      {
        class: this.getClass(this.actions.ViewMyDataMenu),
        name: translate?.MenuMyData || "My Data",
        url: "*",
        icon: "las la-server la-lg",
        children: [
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewDataExport),
            name: translate?.MenuMyData_DataExport || "Data Export",
            url: "*",
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewManualInputSubMenu),
            name: translate?.MenuMyData_Manual || "Manual Input",
            url: "/manualinput",            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewScreenData),
            name: translate?.MenuMyData_DataImport || "Data Import",
            url: "/my-data/data-import",            
          },
        ],
      },
      {
        class: this.getClass(this.actions.ViewContactListInMyContacts),
        name: translate?.MenuMyContacts || 'My Contacts',
        url: '/contacts/sb',
        icon: 'las la-globe-americas la-lg',
      },
      {
        class: this.getClass(this.actions.ViewClientManagementMenu),
        name: translate?.MenuClientManagement || 'My Client Manager',
        url: '*',
        icon: 'las la-tools la-lg',
        children: [
          {
          class: 'nav-link__item__children ' + this.getClass(this.actions.ViewAccountConfig),
            name: translate?.MenuClientManagement_Configuration || "Client Configuration",
            url: ["/client/account-config","corporate","edit"],
            linkProps: { queryParams: { isEdit: true } },
            
          },
          {
          class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientUsersList),
            name: translate?.MenuClientManagement_Users || "Client Users",
            url: "/client/users",
            
          },
          {
          class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientSPD),
            name: translate?.MenuClientManagement_SPD || "Benefit Summary Editor",
            url: ["/client/content-editor", "spd"],
            
          },
          {
          class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientMarketPractice),
            name: translate?.MenuClientManagement_SPD || "Market Practice Editor",
            url: ["/client/content-editor", "bm"],
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientGeneralMaintenanceInflation),
            name: "General Inflation",
            url: "client/generalInflation"
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientGeneralMaintenanceSalaryIncrease),
            name: "Salary Increase",
            url: "client/salaryIncrease"
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientGeneralMaintenanceMedicalTrend),
            name: "Medical Trend",
            url: "client/medicalTrend"
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientGeneralMaintenance),
            name: "Data Maintenance",
            url: "client/generalMaintenance/generalInflation",
            children: [
              {
                class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientVendors),
                name: translate?.MenuClientManagement_Vendors || "Client Vendors",
                url: "client/dataMaintenance/vendors",
                
              },
              {
                class: 'nav-link__item__children ' + this.getClass(this.actions.ViewClientForeignExchange),
                name: translate?.MenuClientManagement_ForeignExchange || "Foreign Exchange",
                url: "client/dataMaintenance/currencyexchange",
                
              },
            ]
          },
        ]
      },
      {
        class: this.getClass(this.actions.ViewApexKeeperMenu),
        name: translate?.MenuApexKeeper || 'HUB Administrator',
        url: '*',
        icon: "las la-clipboard-list la-lg",
        children: [
          {
            class: 'nav-link__item ' + this.getClass(this.actions.ViewInformationCenterSubMenu),
            name: translate?.MenuClientManagement_Configuration || "Information Center",
            url: "*",
            
            children: [
              {
                class: 'nav-link__item ' + this.getClass(this.actions.ViewApexCountryReportsSubMenu),
                name: translate?.MenuClientManagement_Users || "Country Reports",
                url: "*",                
                children: [
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexCountryHealthSummaries),
                    name: translate?.MenuClientManagement_Users || "Country Health Summaries Manager",
                    url: ["/hub/reports","rs","Country_Health_Summaries"],
                    
                  },
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexCountryEmployeeBenefitGuide),
                    name: translate?.MenuClientManagement_Users || "Country Employee Benefit Summary Manager",
                    url: ["/hub/reports","rs","Country_Employee_Benefits_Summary"],
                    
                  },
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexCountryBenefitSummaries),
                    name: translate?.MenuClientManagement_Users || "Country Retirement Benefit Summaries Manager",
                    url: ["/hub/reports","rs", "Country_Retirement_Benefit_Summaries"],
                    
                  },
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexStatutoryRequirements),
                    name: translate?.MenuClientManagement_Users || "Statutory Requirements Reports Editor",
                    url: ["/hub/content-editor", "sr"],
                    
                  },
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexMarketPractice),
                    name: translate?.MenuClientManagement_Users || "Benefit Market Practice Reports Editor",
                    url: ["/hub/content-editor", "bm"],
                    
                  },  
                ]
              },
              {
                class: 'nav-link__item ' + this.getClass(this.actions.ViewApexNewAndInsightsSubMenu),
                name: translate?.MenuClientManagement_Users || "News & Insights",
                url: "*",                
                children: [
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexLegistalativeDevelopments),
                    name: translate?.MenuClientManagement_Users || "Legislative Developments Bulletin Manager",
                    url: ["/hub/reports","rs", "Country_Legislative_Developments_Bulletins"],                    
                  },    
                ]
              },
              {
                class: 'nav-link__item ' + this.getClass(this.actions.ViewApexLoginPageContent),
                name: translate?.MenuClientManagement_Users || "Login Page Content",
                url: "/hub/login-content-editor",
                
              },
              {
                class: 'nav-link__item ' + this.getClass(this.actions.ViewApexDataMaintenanceSubMenu),
                name: translate?.MenuClientManagement_Users || "Data Maintenance",
                url: "*",
                
                children: [
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexVendors),
                    name: translate?.MenuClientManagement_Users || "Vendors Management",
                    url: "/hub/vendors",
                  },                  
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexGeneralMaintenanceInflation),
                    name: "General Inflation",
                    url: "hub/generalInflation"
                  },
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexGeneralMaintenanceSalaryIncrease),
                    name: "Salary Increase",
                    url: "hub/salaryIncrease"
                  },
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexGeneralMaintenanceMedicalTrend),
                    name: "Medical Trend",
                    url: "client/medicalTrend"
                  },
                  {
                    class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexGeneralMaintenance),
                    name: "Data Maintenance",
                    url: "*",
                    children: [
                      {
                        class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexForeignExchange),
                        name: translate?.MenuClientManagement_Users || "Foreign Exchange",
                        url: "client/dataMaintenance/currencyexchange",
                      },
                    ]
                  },
                ]
              },    
            ]
          },
          {
            class: 'nav-link__item ' + this.getClass(this.actions.ViewApexAccountsSubMenu),
            name: translate?.MenuClientManagement_Users || "Client Accounts",
            url: "hub/accounts",            
          },
          {
            class: 'nav-link__item ' + this.getClass(this.actions.ViewApexSecuritySubMenu),
            name: translate?.MenuApexKeeper_Security || "Security",
            url: "*",
            
            children: [
              {
                class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexUser),
                name: translate?.MenuSecurity_Users || "Users",
                url: "/security/users",                
              },
              {
                class: 'nav-link__item__children ' + this.getClass(this.actions.ViewApexRoles),
                name: translate?.MenuSecurity_Roles || "Roles",
                url: "/security/roles",                
              },
            ],
          }
        ]
      },
      {
        class: this.getClass(this.actions.ViewSystemMenu),
        name: translate?.MenuGeneral || "System",
        url: "*",
        icon: "las la-sliders-h la-lg",
        children: [
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.Resources),
            name: 'Resources',            
            url:'general/resources'
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewSupportList),
            name: translate?.MenuGeneral_SupportList || 'Support List',
            url: '/general/inputsupport',
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewBenefitTypeList),
            name: translate?.MenuGeneral_BenefitTypeList || 'Benefit Type List',
            url: '/general/benefitprogram',
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewBenefitProgramTemplateList),
            name: translate?.MenuGeneral_BenefitProgramTemplate || 'Benefit Program Template',
            url: '/general/benefitprogramtemplate',
            
          },
          {
            class: 'nav-link__item__children ' + this.getClass(this.actions.ViewCountriesList),
            name: translate?.MenuGeneral_Countries || "Countries",
            url: "/general/country",
            
          }
        ],
      },
      
    ]) 
  }
}